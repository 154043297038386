<template>
  <div class="table mt-5">
    <v-data-table
      :headers="headers"
      :items="items"
      :hide-default-footer="true"
      no-data-text="Aucun élément ajouté"
      :items-per-page="12"
      dense
    >
      <template v-slot:top>
        <v-dialog :value="dialog" max-width="500px" persistent>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="subform" v-model="validSubform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-textarea
                        dense
                        outlined
                        label="Description *"
                        required
                        :rules="[validation.description]"
                        v-model="editedItem.description"
                        rows="3"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        dense
                        outlined
                        label="Quantité"
                        type="number"
                        v-model="editedItem.quantity"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        dense
                        outlined
                        label="N° Article, code, numéro, dimensions"
                        v-model="editedItem.serial"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialog"
                >Annuler</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :disabled="!validSubform || !hasDescription"
                >Sauver</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:body="{ items }">
        <tbody class="text-left">
          <tr v-for="(item, index) in items" :key="item._id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ item.serial }}</td>
            <td class="text-center">
              <v-btn
                x-small
                text
                class="mr-2"
                @click="edit(item)"
                :disabled="disabled"
                >Editer</v-btn
              >
              <v-btn
                x-small
                text
                color="red"
                class="mr-2"
                @click="removeItem(item)"
                :disabled="disabled"
                >Supprimer</v-btn
              >
            </td>
          </tr>
        </tbody></template
      >
    </v-data-table>
    <v-divider />
    <v-btn
      class="my-1"
      color="grey darken-3"
      text
      @click="dialog = true"
      v-if="items.length < 12"
      small
      :disabled="disabled"
      >Ajouter un élément</v-btn
    >
  </div>
</template>

<script>
export default {
  props: ["readOnly", "items", "disabled"],
  data: () => ({
    dialog: false,
    editedIndex: -1,
    editedItem: {
      description: "",
      quantity: "",
      serial: "",
    },
    validSubform: false,
    validation: {
      description: (value) => !!value || "Ce champ est requis.",
    },
    headers: [
      {
        text: "POS",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Description",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Quantité",
        align: "start",
        sortable: false,
        value: "quantity",
      },
      {
        text: "N° Article, code, numéro, dimensions",
        align: "start",
        sortable: false,
        value: "serial",
      },
      { text: "Actions", value: "actions", sortable: false, align: "center" },
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Ajouter un élément"
        : "Éditer un élément";
    },
    hasDescription() {
      return (
        this.editedItem &&
        this.editedItem.description &&
        this.editedItem.description.length > 0
      );
    },
  },
  watch: {
    dialog(val) {
      val || this.closeDialog();
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    edit(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    addItem(item) {
      this.items.push(item);
    },
    removeItem(item) {
      const index = this.items.indexOf(item);
      confirm("Voulez-vous supprimer cet élément?") &&
        this.items.splice(index, 1);
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.addItem(this.editedItem);
      }
      this.closeDialog();
    },
  },
};
</script>

<style scoped>
.table {
  border: solid 1px rgba(0, 0, 0, 0.38);
  border-radius: 5px;
}
</style>