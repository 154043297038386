<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col cols="12" md="8">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          v-if="current"
          :disabled="!!current.deletedAt || current.status !== 'draft'"
        >
          <purchase-order-form
            :current="current"
            :generating="generating"
            :computedValue="computedValue"
            :computedVat="computedVat"
            :computedDelivery="computedDelivery"
            :computedTotal="computedTotal"
            :requiredFields="requiredFields"
            :rates="rates" /></v-form
      ></v-col>
      <v-col cols="12" md="4">
        <div style="position: sticky; top: 76px">
          <v-btn
            outlined
            block
            :loading="generating"
            @click="
              createOrder({
                rates,
                ...current,
                computedTotal,
              })
            "
            :disabled="
              !this.requiredFields ||
              !this.valid ||
              !!current.deletedAt ||
              current.status !== 'draft'
            "
            >Générer</v-btn
          >
          <validation-list
            :po="current"
            :computedValue="computedValue"
          ></validation-list>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import PurchaseOrderForm from "@/components/PurchaseOrderForm";
import ValidationList from "@/components/ValidationList";
import { formatISO } from "date-fns";
import axios from "axios";

export default {
  components: { PurchaseOrderForm, ValidationList },
  async mounted() {
    const poFromStorage = JSON.parse(localStorage.getItem("po"));
    const supplierFromStorage = JSON.parse(localStorage.getItem("supplier"));
    if (Object.keys(poFromStorage).length) {
      this.current = poFromStorage;
      localStorage.setItem("po", JSON.stringify({}));
    }
    if (Object.keys(supplierFromStorage).length) {
      const supplier = supplierFromStorage;
      this.current.supplier.email = supplier.email;
      this.current.supplier.secondEmail = supplier.secondEmail;
      this.current.supplier.address =
        `${supplier.addressOne}\n${supplier.addressTwo}\n${supplier.zip} ${supplier.city}\n${supplier.country}`
          .replace(/(\r\n|\r|\n){2}/g, "$1")
          .replace(/(\r\n|\r|\n){3,}/g, "$1\n");
      this.current.supplier.name = supplier.name;
      this.current.supplier.contact = supplier.contact;
      this.current.supplier.phone = supplier.phone;
      localStorage.setItem("supplier", JSON.stringify({}));
    }
    if (this.current && !this.current.rates) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/exchange-rates`,
      });
      const chfRates = {};
      Object.keys(data.rates).map(
        (k) => (chfRates[k] = data.rates[k] / data.rates["CHF"])
      );
      this.rates = { ...chfRates, date: data.timestamp };
    } else {
      this.rates = this.current.rates;
    }
  },
  props: ["updateBadge"],
  data: () => ({
    generating: false,
    current: {
      supplier: { email: "", address: "", name: "", phone: "", contact: "" },
      delivery: "HEIG-VD\nRoute de Cheseaux 1\nCH-1401 Yverdon-les-Bains",
      value: null,
      recipient: "",
      year: new Date().getFullYear().toString(),
      account: "",
      costCenter: "",
      comments: "",
      items: [],
      currency: "CHF",
      deliveryCurrency: "CHF",
      deliveryFees: null,
      buyer: "Automatique",
      offer: "",
      otherOffers: [],
      document: "",
      otherDocuments: [],
      customUpload: null,
      clientNumber: "",
      projectNumber: "",
      offerRef: "",
      offerRefDate: "",
      creatorTla: "",
      poDate: formatISO(new Date()),
      deliveryIncluded: true,
      vatIncluded: true,
      status: "draft",
      payment: "Facture",
      vat: 8.1,
      budget: null,
    },
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
    valid: false,
    rates: {},
  }),
  methods: {
    async createOrder(po) {
      this.generating = true;
      try {
        const { data: order } = await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/orders`,
          data: {
            ...po,
          },
        });
        localStorage.setItem("order", JSON.stringify(order));
        localStorage.setItem(
          "snackbar",
          JSON.stringify({
            visible: true,
            color: "success",
            text: "Bon de commande créé",
          })
        );
        this.updateBadge("drafts", 1);
        this.$router.push(`/orders/${order._id}`);
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur de création du bon de commande",
        };
      } finally {
        this.generating = false;
      }
    },
  },
  computed: {
    computedValue() {
      return this.current.currency === "CHF"
        ? this.current.value
        : this.current.value / this.rates[this.current.currency];
    },
    computedDelivery() {
      if (this.current.deliveryIncluded) {
        return 0;
      } else {
        if (this.current.deliveryCurrency === "CHF") {
          return this.current.deliveryFees;
        } else {
          return (
            this.current.deliveryFees /
            this.rates[this.current.deliveryCurrency]
          );
        }
      }
    },
    computedVat() {
      return this.current.vatIncluded
        ? 0
        : (this.computedValue * this.current.vat) / 100;
    },
    computedTotal() {
      return this.computedValue + this.computedDelivery + this.computedVat;
    },
    requiredFields: function () {
      if (this.computedValue < 5000) {
        return (
          !!this.current.supplier.name &&
          !!this.current.delivery &&
          !!this.current.value &&
          !!this.current.year &&
          !!this.current.creatorTla &&
          this.current.items.length > 0
        );
      } else {
        return (
          !!this.current.supplier.name &&
          !!this.current.delivery &&
          !!this.current.value &&
          !!this.current.year &&
          !!this.current.creatorTla &&
          !!this.current.offer &&
          this.current.items.length > 0
        );
      }
    },
  },
};
</script>
