<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col cols="12" md="8">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          v-if="current"
          :disabled="!!current.deletedAt || current.status !== 'draft'"
        >
          <purchase-order-form
            v-if="current"
            :current="current"
            :generating="generating"
            :computedValue="computedValue"
            :computedVat="computedVat"
            :computedDelivery="computedDelivery"
            :computedTotal="computedTotal"
            :requiredFields="requiredFields"
            :rates="rates"
            :disabled="
              !!current.deletedAt || current.status !== 'draft'
            " /></v-form
      ></v-col>
      <v-col cols="12" md="4">
        <div style="position: sticky; top: 76px">
          <v-btn
            outlined
            block
            :loading="generating"
            @click="
              updateOrder({
                rates,
                ...current,
                computedTotal,
              })
            "
            :disabled="
              (current && !this.requiredFields) ||
              !this.valid ||
              !!current.deletedAt ||
              current.status !== 'draft'
            "
            >Mettre à jour</v-btn
          >
          <v-btn
            color="error"
            outlined
            block
            class="mt-2"
            :disabled="generating"
            @click="updateOrderDate(current)"
            v-if="
              current &&
              ['to-order', 'to-order-contractor'].indexOf(current.status) >
                -1 &&
              orderConfirmation
            "
            >Confirmer</v-btn
          >
          <v-btn
            outlined
            block
            class="mt-2"
            v-if="this.$route.name !== 'ValidationUpdatePo'"
            @click="duplicate"
            :disabled="generating"
            >Dupliquer</v-btn
          >
          <v-btn
            outlined
            block
            class="mt-2"
            @click="downloadBinder(current.binder)"
            :disabled="generating"
            v-if="current && !current.validatedBinder"
            >Télécharger PDF</v-btn
          >
          <v-btn
            v-if="current && current.validatedBinder"
            outlined
            block
            class="mt-2"
            @click="downloadBinder(current.validatedBinder)"
            :disabled="generating"
            >Télécharger BC validé</v-btn
          >
          <v-btn
            outlined
            block
            class="mt-2"
            :disabled="generating"
            @click="openDialog('validation')"
            v-if="
              current &&
              current.status === 'draft' &&
              !current.deletedAt &&
              this.$route.name !== 'ValidationUpdatePo'
            "
            >Soumettre pour validation</v-btn
          >
          <v-btn
            outlined
            block
            class="mt-2"
            :disabled="generating"
            @click="openDialog('accounting')"
            v-if="
              current &&
              current.status === 'draft' &&
              !current.deletedAt &&
              this.$route.name !== 'ValidationUpdatePo'
            "
            >Envoyer à la comptabilité</v-btn
          >
          <v-btn
            outlined
            block
            class="mt-2"
            :disabled="generating"
            @click="openDialog('validator')"
            v-if="current && current.status !== 'draft' && current.updatable"
            >Changer de validateur</v-btn
          >
          <v-btn
            outlined
            block
            class="mt-2"
            :disabled="generating"
            :to="`${this.$route.path}/validations`"
            v-if="current && current.status !== 'draft'"
            >Suivi validation</v-btn
          >
          <v-card outlined v-if="validatorDialog" class="mt-2">
            <v-card-title>Information importante</v-card-title
            ><v-card-text>
              <div>
                Vous pouvez modifier le destinataire de la première validation.
              </div>
              <v-textarea
                outlined
                dense
                label="Commentaire"
                class="mt-5"
                v-model="validationComment"
              ></v-textarea>
              <v-autocomplete
                v-model="selected"
                :items="managers"
                :search-input.sync="searchManagers"
                label="Validateur *"
                outlined
                dense
                @input="clearSearchManagers"
                no-data-text="Tapez le nom d'une personne..."
                class="mt-5" /></v-card-text
            ><v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                small
                color="primary"
                @click="closeDialog"
                :disabled="submitting"
                >Annuler</v-btn
              >
              <v-btn
                outlined
                small
                color="red"
                :loading="submitting"
                :disabled="!selected"
                @click="createValidation(false)"
                >Soumettre</v-btn
              >
            </v-card-actions></v-card
          >
          <v-card outlined v-if="validationDialog" class="mt-2">
            <v-card-title>Information importante</v-card-title
            ><v-card-text>
              <div>
                Une fois soumis à validation, ce bon de commande sera bloqué en
                écriture. Veuillez sélectionner, dans la liste ci-dessous, la
                personne devant valider votre bon de commande.
              </div>
              <v-textarea
                outlined
                dense
                label="Commentaire"
                class="mt-5"
                v-model="validationComment"
              ></v-textarea>
              <v-autocomplete
                v-model="selected"
                :items="managers"
                :search-input.sync="searchManagers"
                label="Validateur *"
                outlined
                dense
                @input="clearSearchManagers"
                no-data-text="Tapez le nom d'une personne..."
                class="mt-5" /></v-card-text
            ><v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                small
                color="primary"
                @click="closeDialog"
                :disabled="submitting"
                >Annuler</v-btn
              >
              <v-btn
                outlined
                small
                color="red"
                :loading="submitting"
                :disabled="!selected"
                @click="createValidation(false)"
                >Soumettre</v-btn
              >
            </v-card-actions></v-card
          >
          <v-card outlined v-if="accountingDialog" class="mt-2">
            <v-card-title
              >Information importante soumission comptabilité</v-card-title
            ><v-card-text>
              <div>
                Une fois soumis à validation, ce bon de commande sera bloqué en
                écriture. Veuillez sélectionner, dans la liste ci-dessous, la
                personne devant valider votre bon de commande.
              </div>
              <v-textarea
                outlined
                dense
                label="Commentaire"
                class="mt-5"
                v-model="validationComment"
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                small
                color="primary"
                @click="closeDialog"
                :disabled="submitting"
                >Annuler</v-btn
              >
              <v-btn
                outlined
                small
                color="red"
                @click="createValidation(true)"
                :loading="submitting"
                >Envoyer</v-btn
              >
            </v-card-actions></v-card
          >
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top right>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import PurchaseOrderForm from "@/components/PurchaseOrderForm";
import axios from "axios";
import FileDownload from "js-file-download";
import _ from "lodash";
import { parseISO, format } from "date-fns";

export default {
  components: { PurchaseOrderForm },
  async mounted() {
    const poFromStorage = JSON.parse(localStorage.getItem("po"));
    const supplierFromStorage = JSON.parse(localStorage.getItem("supplier"));
    if (Object.keys(poFromStorage).length) {
      this.current = poFromStorage;
      localStorage.setItem("po", JSON.stringify({}));
    }
    if (Object.keys(supplierFromStorage).length) {
      const supplier = supplierFromStorage;
      this.current.supplier.email = supplier.email;
      this.current.supplier.address =
        `${supplier.addressOne}\n${supplier.addressTwo}\n${supplier.zip} ${supplier.city}\n${supplier.country}`
          .replace(/(\r\n|\r|\n){2}/g, "$1")
          .replace(/(\r\n|\r|\n){3,}/g, "$1\n");
      this.current.supplier.name = supplier.name;
      this.current.supplier.contact = supplier.contact;
      this.current.supplier.phone = supplier.phone;
      localStorage.setItem("supplier", JSON.stringify({}));
    }
    const existingOrder = JSON.parse(localStorage.getItem("order"));
    const snackbar = JSON.parse(localStorage.getItem("snackbar"));
    this.snackbar = snackbar;
    if (Object.keys(existingOrder).length) {
      this.current = existingOrder;
    } else {
      if (!this.current) {
        const { data: order } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/orders/${this.$route.params._id}`,
        });
        this.current = order;
      }
    }
    window.addEventListener("unload", () => {
      localStorage.setItem(
        "snackbar",
        JSON.stringify({
          visible: false,
          color: "",
          text: "",
        })
      );
      localStorage.setItem("order", JSON.stringify({}));
    });
    if (this.current && !this.current.rates) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/exchange-rates`,
      });
      const chfRates = {};
      Object.keys(data.rates).map(
        (k) => (chfRates[k] = data.rates[k] / data.rates["CHF"])
      );
      this.rates = { ...chfRates, date: data.timestamp };
    } else {
      this.rates = this.current.rates;
    }
    if (this.current && !this.current.vat) {
      this.current.vat = 7.7;
    }
  },
  beforeDestroy() {
    window.removeEventListener("unload", () => {
      localStorage.setItem(
        "snackbar",
        JSON.stringify({
          visible: false,
          color: "",
          text: "",
        })
      );
      localStorage.setItem("order", JSON.stringify({}));
    });
    localStorage.setItem(
      "snackbar",
      JSON.stringify({
        visible: false,
        color: "",
        text: "",
      })
    );
    localStorage.setItem("order", JSON.stringify({}));
  },
  props: ["updateBadge"],
  data: () => ({
    submitting: false,
    current: undefined,
    generating: false,
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
    valid: false,
    rates: {},
    searchManagers: null,
    dialogNames: ["validation", "accounting", "validator"],
    validationDialog: false,
    accountingDialog: false,
    validatorDialog: false,
    managers: [],
    selected: null,
    orderConfirmation: false,
    format,
    parseISO,
    validationComment: "",
  }),
  methods: {
    openDialog(name) {
      this.selected = null;
      this[`${name}Dialog`] = true;
      this.validationComment = "";
      this.dialogNames
        .filter((dn) => dn !== name)
        .map((dn) => {
          this[`${dn}Dialog`] = false;
        });
    },
    closeDialog() {
      this.selected = null;
      this.validationComment = "";
      this.dialogNames.map((dn) => {
        this[`${dn}Dialog`] = false;
      });
    },
    async createValidation(accounting) {
      try {
        this.submitting = true;
        let data = {
          order: { ...this.current, computedTotal: this.computedTotal },
          validationComment: this.validationComment,
        };
        if (!accounting) {
          data.fullname = `${this.selected.firstname} ${this.selected.lastname}`;
          data.email = this.selected.email;
        }

        await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/validations?accounting=${accounting}`,
          data,
        });
        this.current.status = "processing";
        this.updateBadge("drafts", -1);
        this.updateBadge("processing", 1);
        this.closeDialog();
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: error,
        };
        console.log(error);
      } finally {
        this.submitting = false;
      }
    },
    async queryPicker(v, key) {
      this.loading = true;
      const { data: contacts } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_PEOPLEPICKER_URI}/search-employees?s=${v}`,
        headers: {
          "x-api-key": process.env.VUE_APP_PEOPLEPICKER_KEY,
        },
      });
      contacts.map((c) =>
        this[key].push({
          text: `${c.firstname} ${c.lastname}`,
          value: c,
        })
      );
      this[key] = _.uniqBy(this[key], "value.username");
      this[key] = this[key].filter(
        (c) => c.value.email !== this.$keycloak.tokenParsed.email
      );
      this.loading = false;
    },
    clearSearchManagers() {
      this.searchManagers = null;
    },
    duplicate() {
      this.current._id = undefined;
      this.current.orderId = undefined;
      this.current.createdAt = undefined;
      this.current.updatedAt = undefined;
      this.current.deletedAt = undefined;
      this.current.orderedAt = undefined;
      this.current.validatedBinder = undefined;
      this.current.vat = 8.1;
      this.current.poDate = new Date();
      this.current.status = "draft";
      this.current.contractor = undefined;
      localStorage.setItem("po", JSON.stringify(this.current));
      localStorage.setItem(
        "snackbar",
        JSON.stringify({
          visible: true,
          color: "success",
          text: "Bon de commande",
        })
      );
      this.$router.push("/orders/create");
    },
    async updateOrderDate(po) {
      po.orderedAt = new Date();
      await this.updateOrder(po);
      if (po.status === "to-order-contractor") {
        this.updateBadge("toOrderContractor", -1);
      } else {
        this.updateBadge("toOrder", -1);
      }
      this.orderConfirmation = false;
    },
    async updateOrder(po) {
      this.generating = true;
      try {
        const { data: order } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/orders/${this.current._id}`,
          data: {
            ...po,
          },
        });
        this.current = order;
        this.snackbar = {
          visible: true,
          color: "success",
          text: "Bon de commande mis à jour",
        };
      } catch (error) {
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur de mise à jour du bon de commande",
        };
      } finally {
        this.generating = false;
      }
    },
    downloadBinder: async function (filename) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${filename}`,
        responseType: "arraybuffer",
      });
      FileDownload(
        data,
        `E-${this.current.orderId}-${this.current.creatorTla}.pdf`
      );
    },
  },
  computed: {
    computedValue() {
      if (this.current) {
        return this.current.currency === "CHF"
          ? this.current.value
          : this.current.value / this.rates[this.current.currency];
      }
      return "";
    },
    computedDelivery() {
      if (this.current.deliveryIncluded) {
        return 0;
      } else {
        if (this.current.deliveryCurrency === "CHF") {
          return this.current.deliveryFees;
        } else {
          return (
            this.current.deliveryFees /
            this.rates[this.current.deliveryCurrency]
          );
        }
      }
    },
    computedVat() {
      return this.current.vatIncluded
        ? 0
        : (this.computedValue * this.current.vat) / 100;
    },
    computedTotal() {
      return this.computedValue + this.computedDelivery + this.computedVat;
    },
    requiredFields: function () {
      if (this.current) {
        if (this.computedValue < 5000) {
          return (
            !!this.current.supplier.name &&
            !!this.current.delivery &&
            !!this.current.value &&
            !!this.current.year &&
            !!this.current.creatorTla &&
            this.current.items.length > 0
          );
        } else {
          return (
            !!this.current.supplier.name &&
            !!this.current.delivery &&
            !!this.current.value &&
            !!this.current.year &&
            !!this.current.creatorTla &&
            !!this.current.offer &&
            this.current.items.length > 0
          );
        }
      }
      return "";
    },
  },
  watch: {
    searchManagers: _.debounce(function (val) {
      val && val !== this.selected && this.queryPicker(val, "managers");
    }, 300),
  },
};
</script>
