<template>
  <div>
    <div v-for="(validation, index) in validations" :key="validation._id">
      <v-row>
        <v-col>
          <v-card
            outlined
            @click="selectBinder(validation.order.binder, index + 1)"
            :ripple="false"
            v-if="['validation', 'order'].indexOf(validation.type) !== -1"
            :class="activeIndex === index + 1 ? `teal lighten-5` : ''"
          >
            <v-card-actions>
              <v-list-item class="grow">
                <v-tooltip
                  left
                  v-if="
                    [
                      'to-order-buyer-pending',
                      'to-order-contractor-pending',
                    ].indexOf(validation.status) !== -1
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar
                      v-bind="attrs"
                      v-on="on"
                      color="blue"
                      class="mr-4"
                    >
                      <v-icon color="white" small
                        >mdi-package-variant-closed</v-icon
                      >
                    </v-list-item-avatar></template
                  ><span>À commander</span></v-tooltip
                >
                <v-tooltip
                  left
                  v-if="['ordered'].indexOf(validation.status) !== -1"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar
                      v-bind="attrs"
                      v-on="on"
                      color="success"
                      class="mr-4"
                    >
                      <v-icon color="white" small
                        >mdi-package-variant-closed-check</v-icon
                      >
                    </v-list-item-avatar></template
                  ><span
                    >Commande passée le
                    {{
                      format(parseISO(validation.orderedAt), "dd.MM.yyyy")
                    }}</span
                  ></v-tooltip
                >
                <v-tooltip
                  left
                  v-if="
                    ['cancel-pending', 'cancel'].indexOf(validation.status) !==
                    -1
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="mr-4"
                    >
                      <v-icon color="white" small
                        >mdi-timeline-remove-outline</v-icon
                      >
                    </v-list-item-avatar></template
                  ><span>Demande de validation annulée</span></v-tooltip
                >
                <v-tooltip left v-if="validation.status === 'rejected'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar
                      v-bind="attrs"
                      v-on="on"
                      color="warning"
                      class="mr-4"
                    >
                      <v-icon color="white" small>mdi-undo</v-icon>
                    </v-list-item-avatar></template
                  ><span>Validation refusée</span></v-tooltip
                >

                <v-tooltip left v-if="validation.status === 'po'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar
                      v-bind="attrs"
                      v-on="on"
                      color="grey"
                      class="mr-4"
                    >
                      <v-icon color="white" small
                        >mdi-file-document-outline</v-icon
                      >
                    </v-list-item-avatar></template
                  ><span>Création du bon de commande</span></v-tooltip
                >
                <v-tooltip left v-if="validation.status === 'pending'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar
                      v-bind="attrs"
                      v-on="on"
                      color="blue"
                      class="mr-4"
                    >
                      <v-icon color="white" small>mdi-clock-outline</v-icon>
                    </v-list-item-avatar></template
                  ><span>En attente de validation</span></v-tooltip
                >
                <v-tooltip left v-if="validation.status === 'redirected'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar
                      v-bind="attrs"
                      v-on="on"
                      color="warning"
                      class="mr-4"
                    >
                      <v-icon color="white" small>mdi-routes</v-icon>
                    </v-list-item-avatar></template
                  ><span>Redirigée</span></v-tooltip
                >

                <v-tooltip
                  left
                  v-if="
                    [
                      'validated',
                      'to-order-buyer',
                      'to-order',
                      'ordered-supplier',
                    ].indexOf(validation.status) > -1
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar
                      v-bind="attrs"
                      v-on="on"
                      color="success"
                      class="mr-4"
                    >
                      <v-icon color="white" small>mdi-check</v-icon>
                    </v-list-item-avatar></template
                  ><span>Validation acceptée</span></v-tooltip
                >
                <v-tooltip left v-if="validation.status === 'deleted'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-avatar
                      v-bind="attrs"
                      v-on="on"
                      color="error"
                      class="mr-4"
                    >
                      <v-icon color="white" small>mdi-close</v-icon>
                    </v-list-item-avatar></template
                  ><span>BC supprimé</span></v-tooltip
                >
                <v-list-item-content>
                  <v-list-item-title
                    >{{ validation.fullname
                    }}<span
                      v-if="
                        validation.validatorTla &&
                        validation.email === 'comptabilite@heig-vd.ch'
                      "
                    >
                      - {{ validation.validatorTla }}</span
                    >
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    format(parseISO(validation.updatedAt), "dd.MM.yyyy")
                  }}</v-list-item-subtitle>

                  <v-list-item-subtitle v-if="validation.comment">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div class="comment" v-bind="attrs" v-on="on">
                          1 commentaire
                        </div>
                      </template>
                      <span>{{ validation.comment }}</span>
                    </v-tooltip></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-row align="center" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon :ripple="false" plain>
                        <v-icon
                          class="mr-1"
                          v-if="activeIndex === index + 1"
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            validation &&
                              validation.order &&
                              downloadFile(
                                validation.order.binder,
                                `E-${validation.order.orderId}-${validation.order.creatorTla}.pdf`
                              )
                          "
                        >
                          mdi-cloud-download-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Télécharger BC</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-1"
                        v-if="validation.changed"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert-box-outline
                      </v-icon>
                    </template>
                    <span>Bon de commande modifié</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-1"
                        v-if="
                          validation.type === 'validation' &&
                          [
                            'to-order-contractor-pending',
                            'to-order-buyer-pending',
                            'ordered',
                            'po',
                            'deleted',
                          ].indexOf(validation.status) === -1
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ `mdi-numeric-${validation.level}-box-outline` }}
                      </v-icon>
                    </template>
                    <span>Validation niveau {{ validation.level }}</span>
                  </v-tooltip>
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-card>
          <v-card class="mt-3" outlined v-if="validation.notification">
            <v-card-text>
              <div>
                <div class="subtitle-2">E-mail fournisseur</div>
                <div class="text-right">
                  {{
                    format(
                      parseISO(validation.notification.date),
                      "dd.MM.yyyy HH:mm"
                    )
                  }}
                </div>

                <div>
                  À :
                  <a
                    v-if="typeof validation.notification.to === 'string'"
                    :href="`mailto:${validation.notification.to}`"
                    >{{ validation.notification.to }}</a
                  >
                  <span
                    v-for="(email, i) in validation.notification.to"
                    v-else
                    :key="email"
                  >
                    <a :key="email" :href="`mailto:${email}`">{{ email }}</a>
                    <span v-if="i < validation.notification.to.length - 1"
                      >,
                    </span>
                  </span>
                </div>
                <div>
                  Cc :
                  <span
                    v-for="(cc, index) in validation.notification.cc"
                    :key="cc"
                  >
                    <a :href="`mailto:${cc}`">{{ cc }}</a
                    ><span v-if="validation.notification.cc.length - 1 > index"
                      >,
                    </span></span
                  >
                </div>
                <div>
                  Objet : HEIG-VD - Commande E-{{
                    validation.notification.orderId
                  }}-{{ validation.notification.creatorTla }}
                </div>
                <v-divider class="my-5"></v-divider>
                <div v-html="validation.notification.html" /></div></v-card-text
          ></v-card>

          <div v-if="validation.type === 'notice'">
            <v-card outlined :ripple="false" color="grey lighten-4">
              <v-card-actions>
                <v-list-item class="grow">
                  <v-tooltip left v-if="validation.status === 'pending'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar
                        v-bind="attrs"
                        v-on="on"
                        color="grey"
                        class="mr-4"
                      >
                        <v-icon color="white" small
                          >mdi-information-outline</v-icon
                        >
                      </v-list-item-avatar></template
                    ><span>Demande de préavis envoyée</span></v-tooltip
                  >
                  <v-tooltip left v-if="validation.status === 'approved'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                        class="mr-4"
                      >
                        <v-icon color="white" small>mdi-thumb-up</v-icon>
                      </v-list-item-avatar></template
                    ><span>Préavis favorable</span></v-tooltip
                  >
                  <v-tooltip
                    left
                    v-if="validation.status === 'account-changed'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar
                        v-bind="attrs"
                        v-on="on"
                        color="grey"
                        class="mr-4"
                      >
                        <v-icon color="white" small>mdi-alert-outline</v-icon>
                      </v-list-item-avatar></template
                    ><span>Changement de compte</span></v-tooltip
                  >
                  <v-tooltip left v-if="validation.status === 'disapproved'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar
                        v-bind="attrs"
                        v-on="on"
                        color="warning"
                        class="mr-4"
                      >
                        <v-icon color="white" small>mdi-thumb-down</v-icon>
                      </v-list-item-avatar></template
                    ><span>Préavis défavorable</span></v-tooltip
                  >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ validation.notice.name }}
                      <span
                        v-if="
                          ['approved', 'disapproved'].indexOf(
                            validation.status
                          ) > -1 &&
                          validation.notice.validators.find(
                            (v) => v.email === validation.email
                          ).tla
                        "
                      >
                        -
                        {{
                          validation.notice.validators.find(
                            (v) => v.email === validation.email
                          ).tla
                        }}
                      </span></v-list-item-title
                    >
                    <v-list-item-subtitle v-if="validation.comment">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div class="comment" v-bind="attrs" v-on="on">
                            1 commentaire
                          </div>
                        </template>
                        <span>{{ validation.comment }}</span>
                      </v-tooltip></v-list-item-subtitle
                    >
                  </v-list-item-content></v-list-item
                >
              </v-card-actions>
            </v-card>
            <v-row
              class="mt-1"
              v-if="noticeToValidate(validations, validation)"
            >
              <v-col>
                <v-btn
                  block
                  outlined
                  color="warning"
                  @click="openDialog('rejectNotice')"
                  >Défavorable</v-btn
                >
              </v-col>
              <v-col>
                <v-btn
                  block
                  outlined
                  color="success"
                  @click="openDialog('acceptNotice')"
                  >Favorable</v-btn
                >
              </v-col>
            </v-row>
            <v-divider
              class="mt-5 mb-3"
              v-if="noticeToValidate(validations, validation)"
            />
          </div>
          <v-btn
            v-if="validation.notification"
            class="mt-3"
            block
            outlined
            @click="
              downloadArchive(
                validation._id,
                `E-${validation.notification.orderId}-${validation.notification.creatorTla}-archive.pdf`
              )
            "
            >Télécharger une archive</v-btn
          >
          <v-btn
            v-if="
              !validation.notification &&
              validations.length - 1 === index &&
              ['ordered', 'approved', 'disapproved'].indexOf(
                validation.status
              ) > -1 &&
              validation.type !== 'notice'
            "
            class="mt-3"
            block
            outlined
            @click="
              downloadFile(
                validation.order.binder,
                `E-${validation.order.orderId}-${validation.order.creatorTla}-archive.pdf`
              )
            "
            >Télécharger une archive</v-btn
          >
          <v-btn
            block
            outlined
            color="error"
            @click="openDialog('accountantDeletion')"
            class="mt-2"
            v-if="
              validations.length - 1 === index &&
              [
                'ordered',
                'approved',
                'disapproved',
                'to-order-buyer-pending',
                'ordered',
              ].indexOf(validation.status) > -1 &&
              validation.type !== 'notice' &&
              isAccountant
            "
            >Supprimer</v-btn
          >
        </v-col>
      </v-row>

      <v-row
        v-if="
          validation.status === 'cancel-pending' &&
          (validation.email === $keycloak.tokenParsed.email ||
            validation.validators
              .map((v) => v.email)
              .indexOf($keycloak.tokenParsed.email) !== -1)
        "
        class="mt-0"
      >
        <v-col>
          <v-btn
            block
            outlined
            v-if="!confirmationDialog"
            @click="confirmationDialog = true"
            >Prise de connaissance de l'annulation</v-btn
          ><v-btn
            :loading="loading"
            block
            outlined
            v-else
            @click="acknowledgeCancelation(validation._id)"
            >Confirmer</v-btn
          ></v-col
        >
      </v-row>
    </div>

    <v-row
      v-if="
        currentValidation &&
        currentValidation.email === 'comptabilite@heig-vd.ch' &&
        currentValidation.validators &&
        currentValidation.validators
          .map((v) => v.email)
          .indexOf($keycloak.tokenParsed.email) > -1 &&
        !validationSubmitted &&
        this.dialogNames.map((dn) => this[`${dn}Dialog`]).indexOf(true) === -1
      "
    >
      <v-col cols="12" sm="12">
        <v-btn
          block
          outlined
          :to="`${this.$route.path}/${currentValidation._id}`"
          >Éditer</v-btn
        >
      </v-col>
      <v-col cols="12" sm="12">
        <v-btn block outlined color="warning" @click="openDialog('rejection')"
          >Refuser</v-btn
        >
      </v-col>
      <v-col cols="12" sm="12">
        <v-btn block outlined color="warning" @click="openDialog('redirection')"
          >Rediriger</v-btn
        >
      </v-col>
      <v-col cols="12" sm="12">
        <v-btn block outlined color="error" @click="openDialog('deletion')"
          >Supprimer</v-btn
        >
      </v-col>

      <v-col
        cols="12"
        sm="12"
        v-if="currentValidation.order.buyer === 'Automatique'"
      >
        <v-btn
          block
          outlined
          color="success"
          @click="openDialog('supplier')"
          :disabled="!currentValidation.order.supplier.email"
          >Commande fournisseur</v-btn
        >
      </v-col>
      <v-col
        cols="12"
        sm="12"
        v-if="currentValidation.order.buyer === 'Automatique'"
      >
        <v-btn block outlined color="success" @click="openDialog('other')"
          >Commande autre</v-btn
        >
      </v-col>
      <v-col cols="12" sm="12">
        <v-btn block outlined color="success" @click="openDialog('buyer')"
          >Commande requérant</v-btn
        >
      </v-col>
    </v-row>

    <v-row
      v-if="
        currentValidation &&
        currentValidation.email === $keycloak.tokenParsed.email &&
        !validationSubmitted &&
        [
          'deleted',
          'to-order-buyer-pending',
          'ordered',
          'to-order-contractor-pending',
        ].indexOf(currentValidation.status) === -1 &&
        this.dialogNames.map((dn) => this[`${dn}Dialog`]).indexOf(true) === -1
      "
    >
      <v-col cols="12" sm="6">
        <v-btn
          block
          outlined
          color="warning"
          @click="openDialog('rejection')"
          :disabled="currentValidation.level === 0"
          >Refuser</v-btn
        >
      </v-col>
      <v-col cols="12" sm="6">
        <v-btn block outlined color="success" @click="openDialog('validation')"
          >Valider</v-btn
        >
      </v-col>
      <v-col cols="12" sm="6">
        <v-btn
          block
          outlined
          :to="`${this.$route.path}/${currentValidation._id}`"
          >Éditer</v-btn
        >
      </v-col>
      <v-col
        cols="12"
        sm="6"
        v-if="
          currentValidation.email ===
          validations.find((v) => v.status === 'po').email
        "
      >
        <v-btn block outlined color="error" @click="openDialog('deletion')"
          >Supprimer</v-btn
        >
      </v-col>
      <v-col cols="12" sm="12">
        <v-btn
          block
          outlined
          color="success"
          @click="openDialog('accounting')"
          style="font-size: 9pt"
          >Valider et envoyer à la comptabilité</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="computedUpdatable">
      <v-col cols="12" sm="12">
        <v-btn block outlined @click="openDialog('validator')"
          >Annuler la demande de validation</v-btn
        >
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentValidation &&
        currentValidation.email === $keycloak.tokenParsed.email &&
        ['to-order-buyer-pending', 'to-order-contractor-pending'].indexOf(
          currentValidation.status
        ) !== -1 &&
        validations.map((v) => v.status).indexOf('deleted') === -1
      "
    >
      <v-col>
        <v-btn
          block
          outlined
          v-if="!confirmationDialog"
          @click="confirmationDialog = true"
          >Commande passée</v-btn
        ><v-btn
          :loading="loading"
          block
          outlined
          v-else
          @click="setOrdered(currentValidation._id, currentValidation.status)"
          >Confirmer</v-btn
        ></v-col
      >
    </v-row>
    <v-row
      v-if="
        currentValidation &&
        currentValidation.email === $keycloak.tokenParsed.email &&
        ['to-order-contractor-pending', 'to-order-buyer-pending'].indexOf(
          currentValidation.status
        ) !== -1 &&
        validations.map((v) => v.status).indexOf('deleted') === -1
      "
    >
      <v-col>
        <v-btn
          :loading="loading"
          block
          outlined
          @click="openDialog('returnAccounting')"
          color="warning"
          >Retour comptabilité</v-btn
        >
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentValidation &&
        (currentValidation.email === $keycloak.tokenParsed.email ||
          (currentValidation.validators &&
            currentValidation.validators
              .map((v) => v.email)
              .indexOf($keycloak.tokenParsed.email) > -1)) &&
        validationDialog &&
        !validationSubmitted
      "
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            <div>Information importante sur la validation.</div>
            <v-textarea
              outlined
              dense
              label="Commentaire"
              class="mt-5"
              v-model="validationComment"
            >
            </v-textarea>
            <v-autocomplete
              v-model="selected"
              :items="managers"
              :search-input.sync="searchManagers"
              label="Prochain validateur *"
              outlined
              dense
              @input="clearSearchManagers"
              no-data-text="Tapez le nom d'une personne..."
          /></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="success"
              :disabled="!selected"
              @click="
                updateValidation(
                  selected.email,
                  `${selected.firstname} ${selected.lastname}`,
                  validationComment
                )
              "
              >Valider</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentValidation &&
        (currentValidation.email === $keycloak.tokenParsed.email ||
          (currentValidation.validators &&
            currentValidation.validators
              .map((v) => v.email)
              .indexOf($keycloak.tokenParsed.email) > -1)) &&
        redirectionDialog &&
        !validationSubmitted
      "
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            <div>Information importante sur la redirection.</div>
            <v-textarea
              outlined
              dense
              label="Commentaire"
              class="mt-5"
              v-model="validationComment"
            >
            </v-textarea>
            <v-autocomplete
              v-model="selected"
              :items="managers"
              :search-input.sync="searchManagers"
              label="Prochain validateur *"
              outlined
              dense
              @input="clearSearchManagers"
              no-data-text="Tapez le nom d'une personne..."
          /></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="success"
              :disabled="!selected"
              @click="
                redirectValidation(
                  selected.email,
                  `${selected.firstname} ${selected.lastname}`,
                  validationComment
                )
              "
              >Rediriger</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentValidation &&
        currentValidation.email === $keycloak.tokenParsed.email &&
        accountingDialog &&
        !validationSubmitted
      "
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            <div>Information importante sur l'envoi à la comptabilité.</div>
            <v-textarea
              outlined
              dense
              label="Commentaire"
              class="mt-5"
              v-model="validationComment"
            >
            </v-textarea
          ></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="success"
              @click="submitToAccounting(validationComment)"
              >Envoyer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentValidation &&
        (currentValidation.email === $keycloak.tokenParsed.email ||
          (currentValidation.validators &&
            currentValidation.validators
              .map((v) => v.email)
              .indexOf($keycloak.tokenParsed.email) > -1)) &&
        rejectionDialog &&
        !validationSubmitted
      "
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            <div>Information importante sur le refus.</div>
            <v-textarea
              outlined
              dense
              label="Commentaire *"
              class="mt-5"
              v-model="validationComment"
            >
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="warning"
              @click="rejectValidation(validationComment)"
              :disabled="!validationComment"
              >Refuser</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentValidation &&
        (currentValidation.email === $keycloak.tokenParsed.email ||
          (currentValidation.validators &&
            currentValidation.validators
              .map((v) => v.email)
              .indexOf($keycloak.tokenParsed.email) > -1)) &&
        returnAccountingDialog &&
        !validationSubmitted
      "
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            <div>Information importante sur le retour à la comptabilité.</div>
            <v-textarea
              outlined
              dense
              label="Commentaire *"
              class="mt-5"
              v-model="validationComment"
            >
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="warning"
              @click="returnAccounting(validationComment)"
              :disabled="!validationComment"
              >Retourner</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="isAccountant && accountantDeletionDialog && !validationSubmitted"
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            Information importante sur la suppression.
            <v-textarea
              outlined
              dense
              label="Commentaire *"
              class="mt-5"
              v-model="validationComment"
            >
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="error"
              @click="deletePoForAccountant(validationComment)"
              :disabled="!validationComment"
              >Supprimer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentValidation &&
        (currentValidation.email === $keycloak.tokenParsed.email ||
          (currentValidation.validators &&
            currentValidation.validators
              .map((v) => v.email)
              .indexOf($keycloak.tokenParsed.email) > -1)) &&
        deletionDialog
      "
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            Information importante sur la suppression.
            <v-textarea
              outlined
              dense
              label="Commentaire *"
              class="mt-5"
              v-model="validationComment"
            >
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="error"
              @click="deletePo(validationComment)"
              :disabled="!validationComment"
              >Supprimer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentValidation &&
        (currentValidation.email === $keycloak.tokenParsed.email ||
          (currentValidation.validators &&
            currentValidation.validators
              .map((v) => v.email)
              .indexOf($keycloak.tokenParsed.email) > -1)) &&
        supplierDialog &&
        !validationSubmitted
      "
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            <div>Information importante sur la commande fournisseur.</div>
            <v-text-field
              outlined
              dense
              label="C/C finance"
              class="mt-4"
              v-model="ccFinance"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Pil"
              v-model="pil"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Filière"
              v-model="filiere"
            ></v-text-field>
            <mailer :validation="currentValidation" ref="mailer" />
            <v-autocomplete
              v-model="additionalCcs"
              :items="managers"
              :search-input.sync="searchManagers"
              label="Cc supplémentaires"
              outlined
              dense
              @input="clearSearchManagers"
              no-data-text="Tapez le nom d'une personne..."
              multiple
              class="mt-4"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="success"
              @click="
                orderSupplier(
                  ccFinance,
                  pil,
                  filiere,
                  $refs.mailer.content,
                  additionalCcs
                )
              "
              >Commander</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentValidation &&
        (currentValidation.email === $keycloak.tokenParsed.email ||
          (currentValidation.validators &&
            currentValidation.validators
              .map((v) => v.email)
              .indexOf($keycloak.tokenParsed.email) > -1)) &&
        buyerDialog &&
        !validationSubmitted
      "
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            <div>Information importante sur la commande par le demandeur.</div>
            <v-textarea
              outlined
              dense
              label="Commentaire"
              class="mt-5"
              v-model="validationComment"
            >
            </v-textarea>
            <v-text-field
              outlined
              dense
              label="C/C finance"
              class="mt-4"
              v-model="ccFinance"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Pil"
              v-model="pil"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Filière"
              v-model="filiere"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="success"
              @click="orderBuyer(ccFinance, pil, filiere, validationComment)"
              >Faire commander</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentValidation &&
        (currentValidation.email === $keycloak.tokenParsed.email ||
          (currentValidation.validators &&
            currentValidation.validators
              .map((v) => v.email)
              .indexOf($keycloak.tokenParsed.email) > -1)) &&
        otherDialog &&
        !validationSubmitted
      "
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            <div>
              Information importante sur la commande par une autre personne.
            </div>
            <v-autocomplete
              v-model="selected"
              :items="buyers"
              label="Personne qui passera la commande *"
              outlined
              dense
              no-data-text="Tapez le nom d'une personne..."
              class="mt-5"
              :item-text="(v) => `${v.firstname} ${v.lastname}`"
              :item-value="(v) => v" />
            <v-textarea
              outlined
              dense
              label="Commentaire"
              class="mt-5"
              v-model="validationComment"
            >
            </v-textarea>
            <v-text-field
              outlined
              dense
              label="C/C finance"
              class="mt-4"
              v-model="ccFinance"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Pil"
              v-model="pil"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Filière"
              v-model="filiere"
            ></v-text-field
          ></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="success"
              @click="
                orderOther(selected, ccFinance, pil, filiere, validationComment)
              "
              :disabled="!selected"
              >Faire commander</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        validations &&
        validations.find(
          (v) => v.type === 'notice' && v.status === 'pending'
        ) &&
        validations
          .find((v) => v.type === 'notice' && v.status === 'pending')
          .notice.validators.map((v) => v.email)
          .indexOf($keycloak.tokenParsed.email) > -1 &&
        validations.filter((v) => v.type === 'notice' && v.status === 'pending')
          .length >
          validations.filter(
            (v) => v.type === 'notice' && v.status !== 'pending'
          ).length &&
        acceptNoticeDialog &&
        !validationSubmitted
      "
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            <div>Information importante sur le préavis positif.</div>
            <v-textarea
              outlined
              dense
              label="Commentaire"
              class="mt-5"
              v-model="validationComment"
            >
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="success"
              @click="
                updateNoticeStatus(
                  validations.find(
                    (v) => v.type === 'notice' && v.status === 'pending'
                  ).order._id,
                  validations.find(
                    (v) => v.type === 'notice' && v.status === 'pending'
                  ).notice,
                  'approved',
                  validationComment
                )
              "
              >Valider</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="validatorDialog && !validationUpdated">
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            <div>Information importante sur l'annulation de validation.</div>
            <v-autocomplete
              v-model="selected"
              :items="managers"
              :search-input.sync="searchManagers"
              label="Prochain validateur *"
              outlined
              dense
              @input="clearSearchManagers"
              no-data-text="Tapez le nom d'une personne..."
          /></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="success"
              :disabled="!selected"
              @click="
                cancelValidation(
                  selected.email,
                  `${selected.firstname} ${selected.lastname}`
                )
              "
              >Valider</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="
        validations &&
        validations.find(
          (v) => v.type === 'notice' && v.status === 'pending'
        ) &&
        validations
          .find((v) => v.type === 'notice' && v.status === 'pending')
          .notice.validators.map((v) => v.email)
          .indexOf($keycloak.tokenParsed.email) > -1 &&
        validations.filter((v) => v.type === 'notice' && v.status === 'pending')
          .length >
          validations.filter(
            (v) => v.type === 'notice' && v.status !== 'pending'
          ).length &&
        rejectNoticeDialog &&
        !validationSubmitted
      "
    >
      <v-col>
        <v-card outlined>
          <v-card-title>Information importante</v-card-title>
          <v-card-text>
            <div>Information importante sur le préavis négatif.</div>
            <v-textarea
              outlined
              dense
              label="Commentaire"
              class="mt-5"
              v-model="validationComment"
            >
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined dense @click="closeDialog" :disabled="loading"
              >Annuler</v-btn
            >
            <v-btn
              outlined
              dense
              :loading="loading"
              color="warning"
              @click="
                updateNoticeStatus(
                  validations.find(
                    (v) => v.type === 'notice' && v.status === 'pending'
                  ).order._id,
                  validations.find(
                    (v) => v.type === 'notice' && v.status === 'pending'
                  ).notice,
                  'disapproved',
                  validationComment
                )
              "
              >Valider</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import { format, parseISO } from "date-fns";
import Mailer from "@/components/Mailer.vue";

export default {
  components: { Mailer },
  props: [
    "validations",
    "updateValidation",
    "redirectValidation",
    "rejectValidation",
    "currentValidation",
    "cancelValidation",
    "deletePo",
    "deletePoForAccountant",
    "returnAccounting",
    "selectBinder",
    "activeIndex",
    "validationSubmitted",
    "downloadFile",
    "downloadArchive",
    "updateNoticeStatus",
    "submitToAccounting",
    "orderSupplier",
    "orderBuyer",
    "orderOther",
    "loading",
    "validationUpdated",
    "acknowledgeCancelation",
    "setOrdered",
    "isAccountant",
  ],
  data: () => ({
    ccFinance: "",
    pil: "",
    filiere: "",
    validationComment: "",
    managers: [],
    buyers: [
      {
        firstname: "Raffaele",
        lastname: "Cereghetti",
        email: "raffaele.cereghetti@heig-vd.ch",
        username: "raffaele.cereghet",
      },
      {
        firstname: "Cyril",
        lastname: "Ottonin",
        email: "cyril.ottonin@heig-vd.ch",
        username: "cyril.ottonin",
      },
      {
        firstname: "Jonathan",
        lastname: "Vence",
        email: "jonathan.vence@heig-vd.ch",
        username: "jonathan.vence",
      },
    ],
    selected: null,
    searchManagers: null,
    validationDialog: false,
    rejectionDialog: false,
    deletionDialog: false,
    accountingDialog: false,
    supplierDialog: false,
    buyerDialog: false,
    otherDialog: false,
    redirectionDialog: false,
    returnAccountingDialog: false,
    acceptNoticeDialog: false,
    rejectNoticeDialog: false,
    validatorDialog: false,
    confirmationDialog: false,
    accountantDeletionDialog: false,
    format,
    parseISO,
    dialogNames: [
      "validation",
      "rejection",
      "deletion",
      "accounting",
      "supplier",
      "buyer",
      "other",
      "acceptNotice",
      "rejectNotice",
      "validator",
      "redirection",
      "accountantDeletion",
    ],
    loadingPpl: false,
    additionalCcs: [],
  }),
  watch: {
    searchManagers: _.debounce(function (val) {
      val && val !== this.selected && this.queryPicker(val, "managers");
    }, 300),
  },
  methods: {
    openDialog(name) {
      this.ccFinance = "";
      this.pil = "";
      this.filiere = "";
      this.validationComment = "";
      this[`${name}Dialog`] = true;
      this.dialogNames
        .filter((dn) => dn !== name)
        .map((dn) => {
          this[`${dn}Dialog`] = false;
        });
    },
    closeDialog() {
      this.selected = null;
      this.additionalCcs = [];
      this.ccFinance = "";
      this.pil = "";
      this.filiere = "";
      this.validationComment = "";
      this.dialogNames.map((dn) => {
        this[`${dn}Dialog`] = false;
      });
    },
    async queryPicker(v, key) {
      this.loadingPpl = true;
      const { data: contacts } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_PEOPLEPICKER_URI}/search-employees?s=${v}`,
        headers: {
          "x-api-key": process.env.VUE_APP_PEOPLEPICKER_KEY,
        },
      });
      contacts.map((c) =>
        this[key].push({
          text: `${c.firstname} ${c.lastname}`,
          value: c,
        })
      );
      this[key] = _.uniqBy(this[key], "value.username");
      this[key] = this[key].filter(
        (c) => c.value.email !== this.$keycloak.tokenParsed.email
      );
      this.loadingPpl = false;
    },
    clearSearchManagers() {
      this.searchManagers = null;
    },
    noticeToValidate(validations, validation) {
      return (
        validation.status === "pending" &&
        validation.notice.validators
          .map((v) => v.email)
          .indexOf(this.$keycloak.tokenParsed.email) > -1 &&
        validations.filter((v) => v.type === "notice" && v.status === "pending")
          .length &&
        validations
          .filter(
            (v) =>
              v.type === "notice" &&
              v.notice.number ===
                validations.find(
                  (v) => v.status === "pending" && v.type === "notice"
                ).notice.number
          )
          .map((v) => v.status)
          .indexOf("approved") === -1 &&
        validations
          .filter(
            (v) =>
              v.type === "notice" &&
              v.notice.number ===
                validations.find(
                  (v) => v.status === "pending" && v.type === "notice"
                ).notice.number
          )
          .map((v) => v.status)
          .indexOf("disapproved") === -1
      );
    },
  },
  computed: {
    computedUpdatable() {
      const onlyValidations = this.validations.filter(
        (v) => v.type === "validation"
      );
      const lastValidation = onlyValidations[onlyValidations.length - 1];
      const beforeLastValidation = onlyValidations[onlyValidations.length - 2];
      return (
        beforeLastValidation &&
        lastValidation &&
        lastValidation.status === "pending" &&
        ["cancel", "cancel-pending", "rejected", "redirected"].indexOf(
          beforeLastValidation.status
        ) === -1 &&
        beforeLastValidation.email === this.$keycloak.tokenParsed.email
      );
    },
  },
};
</script>

<style>
.comment {
  text-decoration: underline !important;
}
.v-list-item__content {
  overflow: visible;
}
</style>